import { useEffect } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import OutContainer from '../../components/outCopntainer';
import ComingSoonImage from '../../assets/coming_soon.png';
import Typography from '@mui/material/Typography';

const Wrapper = styled(Box)(() => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const ComingSoonPage = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <OutContainer isneedpaddingtop>
          <Wrapper>
            <Box sx={{ width: 475, height: 502, }}>
              <img src={ComingSoonImage} alt="Coming soon" width="100%" height="100%" />
            </Box>
            <Typography sx={{ fontSize: 40, fontFamily: 'fontBold', }}>
              Coming Soon
            </Typography>
          </Wrapper>
        </OutContainer>
      </Box>
    </>
  );
}

export default ComingSoonPage;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { INFT } from "../../types/iNFT";
import { fetchNFTList } from "./nftAPI";
// import _ from 'lodash';
// import { Project } from "../../types/project";

export interface NFTState {
  status: 'idle' | 'loading' | 'failed',
  list: INFT[],
}

const initialState: NFTState = {
  status: 'idle',
  list: [],
}

export const getNFTListAsync = createAsyncThunk(
  'nft/fetchNFTList',
  async () => {
    const response = await fetchNFTList();
    return response.data;
  },
)

export const nftSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    addNFT: (state, action: PayloadAction<INFT>) => {
      state.list.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNFTListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getNFTListAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        // const newList = _.unionWith(state.list, action.payload, _.isEqual);
        const newList = [...state.list, ...action.payload];
        state.list = newList;
      });
  }
});

export const { addNFT } = nftSlice.actions;

export const selectNFTList = (state: RootState) => state.nft.list;
export const selectGetNFTStatus = (state: RootState) => state.nft.status;
export const selectGetNFTItem = (state: RootState, nftId: string) => {
  const nftList: INFT[] = state.nft.list;
  const dataIndex: number = nftList.findIndex((nft: INFT) => nft.id === nftId);
  return nftList[dataIndex];
}
export const selectGetNFTsOfProject = (state: RootState, projectId: string) => {
  const nfts: INFT[] = [];
  state.nft.list.forEach((item: INFT) => {
    if (item.projectId === projectId) {
      nfts.push(item);
    }
  });
  return nfts;
}

export default nftSlice.reducer;

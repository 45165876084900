import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
      light: '#20FB4B',
    },
    secondary: {
      main: '#19857B',
    },
    error: {
      main: red.A400,
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#A6A6A6',
      disabled: '#A6A6A6',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1400,
    },
  },
  typography: {
    fontFamily: 'fontNormal',
  },
});

export default theme;

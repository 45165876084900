
import Box from '@mui/material/Box';
import { Pagination, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';


import Banner1 from '../assets/banner1.png';
import Banner2 from '../assets/banner2.png';
import Banner3 from '../assets/banner3.png';
// import NewImage from '../assets/new_banner.png'
// import SaleoutImage from '../assets/saleout_banner.png'


export const BooStoreSwiperComponent = (): JSX.Element => {
  return (
    <>
      <Box sx={{ width: '100%', height: 400, paddingBottom: '20px' }}>
        <Swiper
          modules={[Pagination, Autoplay]}
          slidesPerView="auto"

          centeredSlides
          loop
          autoplay={{
            delay: 5000,

            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          className="boo-store-swiper"
        >
          <SwiperSlide>
            <Box sx={{ width: '100%', borderRadius: '31px', boxShadow: '0 8px 10px rgba(0, 0, 0, 0.5)', }}>
              <Box sx={{ width: '100%', position: 'relative', borderRadius: '31px', overflow: 'hidden', }}>
                <img src={Banner1} alt="banner" style={{ width: '100%', objectFit: 'cover' }} />
              </Box>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box sx={{ width: '100%', borderRadius: '31px', boxShadow: '0 8px 10px rgba(0, 0, 0, 0.5)', }}>
              <Box sx={{ width: '100%', position: 'relative', borderRadius: '31px', overflow: 'hidden', }}>
                <img src={Banner2} alt="banner" style={{ width: '100%', objectFit: 'cover' }} />
              </Box>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box sx={{ width: '100%', borderRadius: '31px', boxShadow: '0 8px 10px rgba(0, 0, 0, 0.5)', }}>
              <Box sx={{ width: '100%', position: 'relative', borderRadius: '31px', overflow: 'hidden', }}>
                <img src={Banner3} alt="banner" style={{ width: '100%', objectFit: 'cover' }} />
              </Box>
            </Box>
          </SwiperSlide>
        </Swiper>
      </Box>
    </>
  );
}

interface ProjectItemSwiperProps {
  previews: string[],
}

export const ProjectItemSwiper = ({ previews }: ProjectItemSwiperProps): JSX.Element => {
  return (
    <>
      <Box sx={{ width: '100%', height: '100%' }}>
        <Swiper
          modules={[Navigation]}
          slidesPerView={4}
          spaceBetween={20}
          navigation
          className="project-item-swiper"
        >
          {
            previews.map((item: string, index: number) => (
              <SwiperSlide key={index}>
                <img src={item} alt="project item preview img" width="100%" height="100%" style={{ objectFit: 'cover' }} />
              </SwiperSlide>
            ))
          }
        </Swiper>
      </Box>
    </>
  );
}



import { useEffect, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { useParams } from "react-router";
import OutContainer from "../../components/outCopntainer";
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { selectGetProjectItem } from "../../features/project/projectSlice";
import { store } from "../../app/store";
import { Project, ProjectType } from '../../types/project';
import Button from '@mui/material/Button';
import { alpha, styled } from '@mui/material/styles';
import { ProjectItemSwiper } from '../../components/swiper';
import ProjectBlandBox from '../../components/projectBlandBox';
import ProjectClaim from '../../components/projectClaim';
import ProjectStake from '../../components/projectStake';
import AuthenticationIcon from '../../assets/icon_authentication.png';
import ProjectReviews from '../../components/projectReviews';
import getRatingContent from '../../components/ratingStar';
import EditIcon from '@mui/icons-material/Edit';

const GetBooButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: 20,
  fontFamily: 'fontBold',
  backgroundColor: theme.palette.primary.light,
  textTransform: 'none',
  borderRadius: '287px',
  width: 213,
  height: 60,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.light, 0.9),
  }
}));

const GetBooButtonAnchor = styled('a')(({ theme }) => ({
  ...theme.components?.MuiButton,
  textDecoration: 'none',
}));

const MarketplaceButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  border: `1px solid ${theme.palette.primary.light}`,
  fontSize: 12,
  color: theme.palette.primary.light,
  textTransform: 'none',
  width: 204,
  height: 38,
}));

const EditReviewsButton = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  height: 30,
  fontSize: 16,
  cursor: 'pointer',
}));

const ProjectItem = (): JSX.Element => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [projectItemDetail, setProjectItemDetail] = useState<Project | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (projectId) {
      const project = selectGetProjectItem(store.getState(), projectId);
      console.log('%cproject: ', 'color: MidnightBlue; background: Aquamarine; font-size: 18px;', project);
      setProjectItemDetail(project);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAnchor = () => {
    const element: HTMLElement | null = document.getElementById('getBoo');
    element && window.scrollTo({ behavior: "smooth", top: element.offsetTop - 98, });
  }

  const handleClickMarketplace = () => {
    navigate('/marketplace', {
      state: projectId,
    });
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, paddingBottom: '32px' }}>
        {
          projectItemDetail && <OutContainer isneedpaddingtop>
            <Box
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '25px', height: 90, width: 190, }}
              onClick={() => navigate(-1)}
              component="div"
            >
              <ArrowBackIcon sx={{ fontSize: 20, color: '#FFFFFF' }} />
              <Typography sx={{ fontSize: 16, marginLeft: '12px' }}>
                Boo Store
              </Typography>
            </Box>
            <Box
              sx={{ width: '100%', height: 320, display: 'flex', marginBottom: '40px' }}
              hidden={!projectId || !projectItemDetail}
            >
              <Box sx={{ width: 320, height: 320, borderRadius: '100%', overflow: 'hidden' }}>
                <img src={projectItemDetail.cover} alt="project cover" width="100%" height="100%" />
              </Box>
              <Box sx={{ width: 87, height: 1 }} />
              <Box
                sx={{ flex: '1', display: 'flex', flexDirection: 'column', }}
              >
                <Box
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Box
                    sx={{ height: 68, display: 'flex', alignItems: 'center' }}
                  >
                    <Typography
                      sx={{ fontSize: 30, fontFamily: 'fontBold' }}
                    >
                      {projectItemDetail.name}
                    </Typography>
                  </Box>
                  <GetBooButton variant="contained">
                    <GetBooButtonAnchor onClick={handleAnchor}>
                      Get Boo
                    </GetBooButtonAnchor>
                  </GetBooButton>
                </Box>
                <Box sx={{ height: 30, display: 'flex', alignItems: 'center' }}>
                  <Typography
                    sx={{ fontSize: 16, }}
                  >
                    Create By @{projectItemDetail.creator}
                  </Typography>
                  <img src={AuthenticationIcon} alt="Authentication Icon" width={28} height={28} style={{ marginLeft: '9px' }} />
                </Box>
                <Box sx={{ flex: 1 }} />
                <Box
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <DataComponent title="Ranking" value={projectItemDetail.ranking} />
                  <DataComponent title="Ratings" value={projectItemDetail.rating} />
                  <DataComponent title="Volume" value={projectItemDetail.volume} />
                  <DataComponent title="Total Staked" value={projectItemDetail.totalStaked} />
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: '100%', overflow: 'hidden', marginBottom: '60px', }}>
              <TitleBox title="Preview" />
              <Box sx={{ width: '100%' }}>
                <ProjectItemSwiper previews={projectItemDetail.preview} />
              </Box>
            </Box>
            <Box sx={{ width: '100%', marginBottom: '40px' }}>
              <Box
                sx={{ width: '100%', height: 90, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <Typography
                  sx={{ fontSize: 20, fontFamily: 'fontBold', display: 'inline-block' }}
                  id="getBoo"
                >
                  Get Boo
                </Typography>
                <MarketplaceButton onClick={handleClickMarketplace}>
                  Marketplace
                </MarketplaceButton>
              </Box>
            </Box>
            <Box sx={{ width: '100%', }}>
              {
                projectItemDetail.projectType === ProjectType.blandBox
                  ? <ProjectBlandBox blandBox={projectItemDetail.blandBox} />
                  : projectItemDetail.projectType === ProjectType.claim
                    ? <ProjectClaim />
                    : <ProjectStake />
              }
            </Box>
            <Box sx={{ width: '100%', marginTop: '60px' }}>
              <TitleBox title="Description" />
              <Typography
                sx={{ fontSize: 14, width: '100%', display: 'inline-block' }}
              >
                {projectItemDetail.description}
              </Typography>
            </Box>
            <Box sx={{ width: '100%', marginTop: '58px' }}>
              <TitleBox
                title="Reviews & Ratings"
                rightItem={
                  <EditReviewsButton>
                    <EditIcon sx={{ fontSize: 20, marginRight: '8px', transform: 'translateY(-2px)' }} />
                    Edit Review
                  </EditReviewsButton>
                }
              />
              <ProjectReviews reviews={projectItemDetail.reviews} />
            </Box>
            <Box sx={{ width: '100%', marginTop: '48px', marginBottom: '90px', }}>
              <TitleBox title="Onchain info" />
              <Box
                sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '20px' }}
              >
                <Box sx={{ width: '311px' }}>
                  <Typography
                    sx={{ fontSize: 16, width: '100%', display: 'inline-block', }}
                  >
                    Contract Address
                  </Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography
                    sx={{ fontSize: 14, width: '100%', display: 'inline-block', color: '#A6A6A6', }}
                  >
                    {projectItemDetail.chainInfo.address}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{ width: '100%', display: 'flex', alignItems: 'center', }}
              >
                <Box sx={{ width: '311px' }}>
                  <Typography
                    sx={{ fontSize: 16, width: '100%', display: 'inline-block', }}
                  >
                    Blockchain
                  </Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography
                    sx={{ fontSize: 14, width: '100%', display: 'inline-block', color: '#A6A6A6' }}
                  >
                    {projectItemDetail.chainInfo.blockChain}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </OutContainer>
        }
      </Box >

    </>
  );
}

export default ProjectItem;

interface DataBoxProps extends BoxProps {
  styleData: {
    height: number,
    marginBottom: number,
  }
}

const DataBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'styleData',
})<DataBoxProps>(({ styleData }) => ({
  height: styleData.height,
  display: 'flex',
  alignItems: 'center',
  marginBottom: `${styleData.marginBottom}px`,
}));

interface DataComponentParas {
  title: string,
  value: number | undefined,
}

const DataComponent = ({ value, title }: DataComponentParas): JSX.Element => {
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      <DataBox styleData={{ height: 30, marginBottom: 12 }}>
        <Typography
          sx={{ fontSize: 14, display: 'inline-block', }}
        >
          {title}
        </Typography>
      </DataBox>
      <DataBox styleData={{ height: 50, marginBottom: 0 }}>
        <Typography
          sx={{ fontSize: 30, fontFamily: 'fontBold', display: 'inline-block', }}
        >
          {
            title === 'Volume' || title === 'Total Staked'
              ? value?.toLocaleString('us')
              : value
          }
        </Typography>
      </DataBox>
      <DataBox styleData={{ height: 30, marginBottom: 0 }}>
        {
          title === 'Ratings'
            ? <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', }}>
              {
                getRatingContent(value ?? 0).map((item: JSX.Element) => item)
              }
            </Box>
            : <Typography sx={{ fontSize: 12, color: '#A6A6A6', display: 'inline-block', }}>
              {title === 'Ranking'
                ? 'All Projects'
                : title === 'Volume'
                  ? '$ETH'
                  : '$BGLD'
              }
            </Typography>
        }
      </DataBox>
    </Box>
  );
}

interface TitleBoxProps {
  title: string,
  rightItem?: JSX.Element,
}

const TitleBox = ({ title, rightItem }: TitleBoxProps): JSX.Element => {
  return (
    <Box
      sx={{ width: '100%', height: 90, display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}
    >
      <Typography
        sx={{ fontSize: 20, fontFamily: 'fontBold', letterSpacing: '1px' }}
      >
        {title}
      </Typography>
      {
        rightItem && rightItem
      }
    </Box>
  );
}

import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { INFT, Propety, SaleType } from '../../types/iNFT';
import { selectGetNFTItem } from '../../features/nft/nftSlice';
import { store } from '../../app/store';
import OutContainer from '../../components/outCopntainer';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { styled, alpha } from '@mui/material/styles';
import TurnIcon from '../../assets/icon_turn.png';
import Button from '@mui/material/Button';
import TradesElement from '../../components/trades';
import BidsElement from '../../components/bids';
import { useAppDispatch } from '../../app/hooks';
import { closeLoading, openLoading, openSnackbar, SnackbarType } from '../../features/system/systemSlice';
import { DateCount, sleep, dateCount } from '../../utils/utils';
import ClipboardJS from 'clipboard';

const EndInTimeBox = styled(Box)(() => ({
  padding: '0 13px 0 16px',
  height: 50,
  fontSize: 16,
  fontFamily: 'fontBold',
  borderRadius: '16px',
  backgroundColor: '#222222',
  display: 'flex',
  alignItems: 'center',
}));

const RankingBox = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const RankingBoxTitle = styled(Typography)(() => ({
  fontSize: 14,
}));

const RankingBoxValue = styled(Typography)(() => ({
  fontSize: 30,
  fontFamily: 'fontBold',
  letterSpacing: '2.2px',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: 400,
  height: 60,
  borderRadius: '287px',
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.common.black,
  textTransform: 'none',
  ":hover": {
    backgroundColor: alpha(theme.palette.primary.light, 0.9),
  },
  fontSize: 20,
  fontFamily: 'fontBold',
}));

const INFTItem = (): JSX.Element => {
  const navigate = useNavigate();
  const { iNFTId } = useParams();
  const [iNFTItemDetail, setINFTItemDetail] = useState<INFT | null>(null);
  const [dateCountData, setDateCountData] = useState<DateCount | undefined>(undefined);

  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (iNFTId) {
      const iNFT = selectGetNFTItem(store.getState(), iNFTId);
      setINFTItemDetail(iNFT);

      const clipboard = new ClipboardJS('.iNFT-share-icon');

      clipboard.on('success', function (e) {
        console.info('Action:', e.action);
        console.info('Text:', e.text);
        console.info('Trigger:', e.trigger);

        e.clearSelection();

        dispatch(openSnackbar({
          message: 'The link has been copied to the clipboard!',
          snackbarType: SnackbarType.success,
        }))
      });

      let timer: NodeJS.Timeout | null = null;

      if (iNFT.saleType === SaleType.auction && iNFT.endTime) {
        if (iNFT.endTime - Date.now() > 0) {
          timer = setInterval(() => {
            if ((iNFT.endTime ?? 0) - Date.now() > 0) {
              const count: DateCount = dateCount(iNFT.endTime ?? 0);
              setDateCountData(count);
            } else {
              setDateCountData(undefined);
              if (timer) {
                clearInterval(timer);
                timer = null;
              }
            }
          }, 1000);
        }
      }

      return function cleanUp() {
        if (timer) {
          clearInterval(timer);
          timer = null;
        }
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCliceBuyNow = async () => {
    dispatch(openLoading());
    await sleep(3800);
    dispatch(closeLoading());
    dispatch(openSnackbar({
      message: 'Buy Successed!',
      snackbarType: SnackbarType.success,
    }))
  }

  const handleClicePriceBid = async () => {
    dispatch(openLoading());
    await sleep(1800);
    dispatch(closeLoading());
    dispatch(openSnackbar({
      message: 'Bid Successed!',
      snackbarType: SnackbarType.success,
    }))
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, paddingBottom: '80px', }}>
        {
          iNFTItemDetail && <OutContainer isneedpaddingtop>
            <Box
              sx={{ height: 90, display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '40px', width: 190, }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon sx={{ fontSize: 20, color: '#FFFFFF' }} />
              <Typography sx={{ fontSize: 16, marginLeft: '12px' }}>
                Marketplace
              </Typography>
            </Box>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Box sx={{ width: '100%', height: '100%', }}>
                  <Box sx={{ width: '100%', overflow: 'hidden', marginBottom: '32px', borderRadius: '16px', }}>
                    <img src={iNFTItemDetail.cover} width="100%" height="100%" alt="INFT Cover" />
                  </Box>
                  <Box sx={{ height: 92, display: 'flex', alignItems: 'center', marginBottom: '40px', }}>
                    <RankingBox>
                      <RankingBoxTitle>
                        Rarity Score
                      </RankingBoxTitle>
                      <RankingBoxValue>
                        {iNFTItemDetail.score}
                      </RankingBoxValue>
                    </RankingBox>
                    <Box sx={{ width: 106, height: 1 }} />
                    <RankingBox>
                      <RankingBoxTitle>
                        Ranking
                      </RankingBoxTitle>
                      <RankingBoxValue>
                        {iNFTItemDetail.ranking}
                      </RankingBoxValue>
                    </RankingBox>
                  </Box>
                  <Box
                    sx={{ height: 90, display: 'flex', alignItems: 'center', }}
                  >
                    <Typography sx={{ fontSize: 20, fontFamily: 'fontBold', }}>
                      Properties
                    </Typography>
                  </Box>
                  <Grid container spacing={1.5}>
                    {
                      iNFTItemDetail.properties.map((item: Propety, index: number) => (
                        <Grid item xs={4} key={`properties${index}`}>
                          <Box sx={{ width: '100%', height: '100%', }}>
                            <Box
                              sx={{ width: '100%', height: '100%', borderRadius: '16px', backgroundColor: '#1D231E', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '20px 0' }}
                            >
                              <Typography sx={{ fontSize: 12, color: '#20FB4B', marginBottom: '10px', }}>
                                {item.title}
                              </Typography>
                              <Typography sx={{ fontSize: 14, marginBottom: '10px', }}>
                                {item.name}
                              </Typography>
                              <Typography sx={{ fontSize: 12, color: '#A6A6A6', }}>
                                {item.percent}% have this trait
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      ))
                    }
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ height: 68, display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                    <Typography sx={{ fontSize: 30, fontFamily: 'fontBold', }}>
                      {iNFTItemDetail.name}
                    </Typography>
                    <img className="iNFT-share-icon" src={TurnIcon} data-clipboard-text={window.location.href} alt="Boo Turn Icon" width={60} height={60} style={{ cursor: 'pointer', }} />
                  </Box>
                  <Box sx={{ height: 30, display: 'flex', alignItems: 'center', }}>
                    <Typography sx={{ fontSize: 16, }}>
                      {iNFTItemDetail.projectName}
                    </Typography>
                  </Box>
                  {
                    iNFTItemDetail.saleType === SaleType.fixedPrice
                      ? <Box sx={{ height: 50, display: 'flex', alignItems: 'center', marginBottom: '40px', marginTop: '70px', }}>
                        <Typography
                          sx={{ fontSize: 30, fontFamily: 'fontBold', }}
                        >
                          {iNFTItemDetail.fixedPrice}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 30, marginLeft: '20px', }}
                        >
                          ETH
                        </Typography>
                      </Box>
                      : <Box sx={{ width: '100%', height: 80, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '40px', marginBottom: '40px', }}>
                        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
                          <Typography sx={{ fontSize: 14, }}>
                            End in
                          </Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center', fontFamily: 'fontBold', fontSize: 16, }}>
                            <EndInTimeBox>
                              {dateCountData ? dateCountData.day : 0} Days
                            </EndInTimeBox>
                            <Box sx={{ width: 20, height: 1 }} />
                            <EndInTimeBox>
                              {`${dateCountData ? dateCountData.hour : 0}`.padStart(2, '0')}
                            </EndInTimeBox>
                            &nbsp;
                            :
                            &nbsp;
                            <EndInTimeBox>
                              {`${dateCountData ? dateCountData.minute : 0}`.padStart(2, '0')}
                            </EndInTimeBox>
                            &nbsp;
                            :
                            &nbsp;
                            <EndInTimeBox>
                              {`${dateCountData ? dateCountData.second : 0}`.padStart(2, '0')}
                            </EndInTimeBox>
                          </Box>
                        </Box>

                        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
                          <Typography sx={{ fontSize: 14, }}>
                            {iNFTItemDetail.bids.length ? 'Top bid' : 'Min bid'}
                          </Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center', }}>
                            <Typography sx={{ fontSize: 30, fontFamily: 'fontBold', }}>
                              {iNFTItemDetail.bids.length ? iNFTItemDetail.bids[0].price : iNFTItemDetail.minBid}
                            </Typography>
                            <Typography sx={{ fontSize: 30, }}>
                              WETH
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                  }
                  {
                    iNFTItemDetail.saleType === SaleType.fixedPrice
                      ? <StyledButton onClick={handleCliceBuyNow}>
                        Buy Now
                      </StyledButton>
                      : <StyledButton onClick={handleClicePriceBid}>
                        Price Bid
                      </StyledButton>
                  }
                  <Box sx={{ width: '100%', marginTop: '40px', }}>
                    <Box
                      sx={{ width: '100%', height: 90, display: 'flex', alignItems: 'center', backgroundColor: '#222222', borderTopRightRadius: '16px', borderTopLeftRadius: '16px', paddingLeft: '24px', paddingRight: '32px', }}
                    >
                      <Typography sx={{ fontSize: 20, fontFamily: 'fontBold', }}>
                        Onchain info
                      </Typography>
                    </Box>
                    <Box
                      sx={{ width: '100%', height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#292929', paddingLeft: '24px', paddingRight: '32px', }}
                    >
                      <Typography sx={{ fontSize: 16, }}>
                        Contract Address
                      </Typography>
                      <Typography sx={{ fontSize: 16, display: 'inline-block', maxWidth: 300, textOverflow: 'ellipsis', overflow: 'hidden', }}>
                        {iNFTItemDetail.nftInfo.address}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ width: '100%', height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#292929', paddingLeft: '24px', paddingRight: '32px', }}
                    >
                      <Typography sx={{ fontSize: 16, }}>
                        Token ID
                      </Typography>
                      <Typography sx={{ fontSize: 16, display: 'inline-block', maxWidth: 300, textOverflow: 'ellipsis', overflow: 'hidden', }}>
                        {iNFTItemDetail.nftInfo.tokenId}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ width: '100%', height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#292929', paddingLeft: '24px', paddingRight: '32px', }}
                    >
                      <Typography sx={{ fontSize: 16, }}>
                        Blockchain
                      </Typography>
                      <Typography sx={{ fontSize: 16, display: 'inline-block', maxWidth: 300, textOverflow: 'ellipsis', overflow: 'hidden', }}>
                        {iNFTItemDetail.nftInfo.blockChain}
                      </Typography>
                    </Box>
                  </Box>
                  {
                    iNFTItemDetail.saleType === SaleType.auction && (
                      <Box sx={{ width: '100%', marginTop: '108px' }}>
                        <BidsElement bids={iNFTItemDetail.bids} />
                      </Box>
                    )
                  }
                  <Box sx={{ width: '100%', marginTop: '40px' }}>
                    <TradesElement trades={iNFTItemDetail.trades} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </OutContainer>
        }
      </Box>
    </>
  );
}

export default INFTItem;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Project } from "../../types/project";
import { fetchProjectList } from "./projectAPI";
import _ from 'lodash';

export interface ProjectState {
  status: 'idle' | 'loading' | 'failed',
  list: Project[],
}

const initialState: ProjectState = {
  status: 'idle',
  list: [],
};

export const getProjectListAsync = createAsyncThunk(
  'project/fetchProjectList',
  async () => {
    const response = await fetchProjectList();
    return response.data;
  },
);

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    addProject: (state, action: PayloadAction<Project>) => {
      state.list.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProjectListAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        const newList = _.unionWith(state.list, action.payload, _.isEqual);
        state.list = newList;
      });
  }
});

export const { addProject } = projectSlice.actions;

export const selectProjectList = (state: RootState) => state.project.list;
export const selectGetProjectStatus = (state: RootState) => state.project.status;
export const selectGetProjectItem = (state: RootState, projectId: string) => {
  const projectList: Project[] = state.project.list;
  const dataIndex: number = projectList.findIndex((project: Project) => project.id === projectId);
  return projectList[dataIndex];
}

export default projectSlice.reducer;

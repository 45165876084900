import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Trade } from '../types/iNFT';
import moment from 'moment';

const According = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  backgroundColor: '#222222',
  borderRadius: '16px',
  overflow: 'hidden',
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: '48px', color: '#FFFFFF' }} />}
    {...props}
  />
))(() => ({
  width: '100%',
  height: 90,
  paddingLeft: '24px',
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '0',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#292929',
    color: theme.palette.text.primary,
    fontSize: 16,
    border: 'none',
    height: 61,
    paddingLeft: '24px',
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#222222',
    fontSize: 12,
    border: 'none',
    letterSpacing: '1px',
    height: 90,
    paddingLeft: '24px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#151515',
  border: 'none',
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 'none',
  },
}));

interface TradesElementProps {
  trades: Trade[],
}

const TradesElement = ({ trades }: TradesElementProps): JSX.Element => {
  return (
    <Box sx={{ width: '100%' }}>
      <According defaultExpanded>
        <AccordionSummary aria-controls="trades-content" id="trades-header">
          <Typography sx={{ fontFamily: 'fontBold', fontSize: 20, }}>
            Trades
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Time</StyledTableCell>
                  <StyledTableCell>Buy</StyledTableCell>
                  <StyledTableCell>Sell</StyledTableCell>
                  <StyledTableCell>Price</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  trades.map((row: Trade, index: number) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">
                        <Typography sx={{ color: '#A6A6A6', fontSize: 12, }}>
                          {moment(row.createTime).format('MMM D, YYYY, HH:mm')}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography sx={{ color: '#20FB4B', fontSize: 12, }}>
                          @{row.buyName}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography sx={{ color: '#20FB4B', fontSize: 12, }}>
                          {row.sellName}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <span style={{ fontSize: '12px', color: '#FFFFFF', fontFamily: 'fontBold' }}>
                          {row.price}
                        </span>
                        &nbsp;
                        <span style={{ fontSize: '12px', color: '#FFFFFF', fontFamily: 'fontNormal' }}>
                          ETH
                        </span>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </According>
    </Box>
  );
}

export default TradesElement;

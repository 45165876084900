import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { useAppSelector } from '../../app/hooks';
import { selectProjectList } from '../../features/project/projectSlice';
import { Project } from '../../types/project';
import { INFT, SaleType } from '../../types/iNFT';
import { selectGetNFTsOfProject } from '../../features/nft/nftSlice';
import { store } from '../../app/store';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader'
import Grid from '@mui/material/Grid';
import MarketplactItem from '../../components/marketplaceItem';
import _ from 'lodash';
import EmptyImage from '../../assets/marketplact_empty.png';

const selectStatusValues = [
  'Default',
  'Fixed Price',
  'Auction',
];

const selectSortByValues = [
  'Default',
  'Price: Low to High',
  'Price: High to Low',
  'Recently Listed',
  'Ending Soon',
];

const FilterTitle = styled(Typography)(() => ({
  display: 'inline-block',
  lineHeight: '60px',
  fontSize: 20,
  fontFamily: 'fontBold',
}));

const FilterInputWrapper = styled('div')(() => ({
  width: '100%',
  height: 60,
  borderRadius: '16px',
  position: 'relative',
  backgroundColor: '#292929',
}));

const FilterInputIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const FilterInput = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    width: '100%',
    fontSize: '14px',
    color: '#FFFFFF',
    fontFamily: 'fontNormal',
    padding: theme.spacing(2.5, 0, 0, 0),
    paddingLeft: `calc(1em + ${theme.spacing(5)})`,
    display: 'inline-block',
  },
}));

interface StyledBoxProps extends BoxProps {
  isSelected?: boolean,
}

const ProjectBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<StyledBoxProps>(({ isSelected }) => ({
  width: '100%',
  height: 60,
  borderRadius: '16px',
  backgroundColor: isSelected ? '#252525' : 'transparent',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '12px',
  cursor: 'pointer',
}));

interface StyledTypographyProps extends TypographyProps {
  isSelected?: boolean,
}

const ProjectName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<StyledTypographyProps>(({ isSelected }) => ({
  width: '100%',
  color: isSelected ? '#20FB4B' : '#FFFFFF',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

const ProjectInfo = styled(Box)(() => ({
  width: '100%',
  height: 173,
  borderRadius: 16,
  backgroundColor: '#272727',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: '54px',
  paddingRight: '63px',
}));

const ProjectCountBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  marginLeft: '80px',
}));

const ProjectCountNumber = styled(Typography)(() => ({
  fontSize: 60,
  color: '#20FB4B',
  fontFamily: 'fontBold',
}));

const ProjectCountTitle = styled(Typography)(() => ({
  fontSize: 14,
}));

const SearchInputWrapper = styled('div')(() => ({
  width: '100%',
  height: 60,
  borderRadius: '16px',
  position: 'relative',
  backgroundColor: '#292929',
}));

const SearchInputIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    width: '100%',
    fontSize: '14px',
    color: '#FFFFFF',
    fontFamily: 'fontNormal',
    padding: theme.spacing(2.5, 0, 0, 0),
    paddingLeft: `calc(1em + ${theme.spacing(5)})`,
    display: 'inline-block',
  },
}));

const StyledSelect = styled(Select)(() => ({
  width: 207,
  height: 60,
  borderRadius: '16px',
  backgroundColor: '#292929',
  fontSize: 14,
  '&:hover': {
    borderColor: '#f66',
  }
}));

const StyledList = styled(List)<{ componet?: React.ElementType }>({
  width: '100%',
  height: '100%',
  position: 'relative',
  overflow: 'auto',
  maxHeight: '100%',
  '& ui': {
    padding: 0,
  },
  '&::-webkit-scrollbar': {
    width: '0 !important',
  },
  msOverflowStyle: 'none',
});

const StickyListItem = styled(ListSubheader)(({ theme }) => ({
  color: theme.palette.common.white,
  padding: 0,
  backgroundColor: '#151515',
  paddingBottom: '24px',
}));

type INFTFilter = {
  nameKeywords: string,
  saleType: SaleType | null,
  sort: ItemSort,
}

enum ItemSort {
  Default = 0,
  PriceLow2High = 1,
  PriceHigh2Low = 2,
  RecentlyListed = 3,
  EndingSoon = 4,
}

const MarketPlace = () => {
  const location = useLocation();
  const projectList = useAppSelector(selectProjectList);

  const defaultIndex = location.state ? projectList.findIndex((item: Project) => item.id === location.state) : 0;

  const [selectedIndex, setSelectedIndex] = useState(defaultIndex);
  const [nftList, setNFTList] = useState<INFT[]>([]);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [selectedSortBy, setSelectedSortBy] = useState(0);
  const [filtedProjectList, setFiltedProjectList] = useState<Project[]>(_.cloneDeep(projectList));
  const defaultFilter: INFTFilter = {
    nameKeywords: '',
    saleType: null,
    sort: ItemSort.Default,
  }
  const [itemFilter, setItemFilter] = useState(defaultFilter);

  const [projectFilterKeywords, setProjectFilterKeywords] = useState('');
  console.log(projectFilterKeywords);

  const handleClickProject = (index: number) => {
    setSelectedIndex(index);
    setItemFilter({ ...itemFilter })
    // console.log(itemFilter)
  }

  const getNFTItems = () => {
    const projectId: string = filtedProjectList[selectedIndex]?.id;
    if (projectId) {
      const nfts: INFT[] = selectGetNFTsOfProject(store.getState(), projectId);
      setNFTList(nfts);
    } else {
      setNFTList([]);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getNFTItems();
  }, [selectedIndex, filtedProjectList]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectedStatusChange = (event: SelectChangeEvent<any>) => {
    setItemFilter({
      ...itemFilter, ...{
        saleType: (event.target.value === 0 || event.target.value === 1) ? null : event.target.value === 2 ? SaleType.fixedPrice : SaleType.auction
      }
    })
    // console.log('event.target.value', event.target.value)
    setSelectedStatus(event.target.value);
  };

  const handleSelectedSortByChange = (event: SelectChangeEvent<any>) => {
    setSelectedSortBy(event.target.value);
  };

  const itemFilterAction = (item: INFT) => {
    return item.name.indexOf(itemFilter.nameKeywords) > -1 && (itemFilter.saleType === null ? true : item.saleType === itemFilter.saleType);
  }

  const itemFilterSort = (item1: INFT, item2: INFT): number => {
    switch (itemFilter.sort) {
      case ItemSort.Default:
        return 1;
      case ItemSort.EndingSoon:
        if (item1.bids[0].endTime > item2.bids[0].endTime) {
          return 1
        } else {
          return -1
        }
      case ItemSort.PriceHigh2Low:
        if ((item1.fixedPrice || 0) > (item2.fixedPrice || 0)) {
          return 1
        } else {
          return -1
        }
      case ItemSort.PriceLow2High:
        if ((item1.fixedPrice || 0) < (item2.fixedPrice || 0)) {
          return 1
        } else {
          return -1
        }
      case ItemSort.RecentlyListed:
        if ((item1.listTime || 0) < (item2.listTime || 0)) {
          return 1
        } else {
          return -1
        }
      default:
        return 1;
    }
  }

  const handleProjectFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setProjectFilterKeywords(event.target.value);

    const filtedList: Project[] = projectList.filter((item: Project) => item.name.indexOf(event.target.value) > -1);
    setFiltedProjectList(_.cloneDeep(filtedList));
    setSelectedIndex(0);
  }

  return (
    <>
      <Box
        sx={{ width: '100%', height: '100vh', paddingTop: '102px', paddingLeft: '36px', paddingRight: '36px' }}
      >
        <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
          <Box sx={{ width: 318, height: '100%', padding: '0 0 15px 0', display: 'flex', flexDirection: 'column' }}>
            <FilterTitle>
              All Projects
            </FilterTitle>
            <FilterInputWrapper>
              <FilterInputIconWrapper>
                <SearchIcon />
              </FilterInputIconWrapper>
              <FilterInput
                placeholder="Filter"
                inputProps={{ 'aria-label': 'filter' }}
                onChange={handleProjectFilterChange}
              />
            </FilterInputWrapper>
            <Box sx={{ flexGrow: 1, overflow: 'hidden', padding: '28px 0' }}>
              <Box
                sx={{ width: '100%', height: '100%', overflowY: 'auto' }}
              >
                {
                  filtedProjectList.map((item: Project, index: number) => (
                    <ProjectBox isSelected={selectedIndex === index} key={index} onClick={() => { handleClickProject(index) }}>
                      <Box
                        sx={{ width: 48, height: 48, borderRadius: '100%', overflow: 'hidden' }}
                      >
                        <img src={item.cover} alt="project cover" width="100%" height="100%" style={{ objectFit: 'cover' }} />
                      </Box>
                      <Box sx={{ width: 12, height: 1 }} />
                      <Box sx={{ flexGrow: 1 }}>
                        <ProjectName isSelected={selectedIndex === index}>
                          {item.name}
                        </ProjectName>
                      </Box>
                    </ProjectBox>
                  ))
                }
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: '37px', height: '100%', }} />
          <Box sx={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
            <StyledList disablePadding subheader={<li />}>
              <li>
                <ul style={{ padding: '0', }}>
                  {
                    filtedProjectList.length > selectedIndex && (
                      <ProjectInfo>
                        <Typography
                          sx={{ fontFamily: 'fontBold', fontSize: 32 }}
                        >
                          {filtedProjectList[selectedIndex].name}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <ProjectCountBox>
                            <ProjectCountNumber>
                              {filtedProjectList[selectedIndex].totalSupply.toLocaleString('en-US')}
                            </ProjectCountNumber>
                            <ProjectCountTitle>
                              Total Supply
                            </ProjectCountTitle>
                          </ProjectCountBox>
                          <ProjectCountBox>
                            <ProjectCountNumber>
                              {filtedProjectList[selectedIndex].circulatingSupply.toLocaleString('en-US')}
                            </ProjectCountNumber>
                            <ProjectCountTitle>
                              Circulating Supply
                            </ProjectCountTitle>
                          </ProjectCountBox>
                        </Box>
                      </ProjectInfo>
                    )
                  }
                </ul>
              </li>
              <li>
                <ul style={{ padding: '0', }}>
                  <StickyListItem>
                    <Box
                      sx={{ width: '100%', height: 60, marginTop: '24px', display: 'flex', alignItems: 'center' }}
                    >
                      <Box sx={{ flexGrow: 1 }}>
                        <SearchInputWrapper>
                          <SearchInputIconWrapper>
                            <SearchIcon />
                          </SearchInputIconWrapper>
                          <SearchInput
                            placeholder="Search items"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setItemFilter({ ...itemFilter, ...{ nameKeywords: event.target.value } })}
                          />
                        </SearchInputWrapper>
                      </Box>
                      <Box sx={{ maxWidth: 300, width: '100%' }} />
                      <Box
                        sx={{ width: 430, height: 60, display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}
                      >
                        <StyledSelect
                          labelId="status-select-label"
                          id="status-select"
                          value={selectedStatus}
                          onChange={handleSelectedStatusChange}
                          className="marketplace-select"
                          IconComponent={ExpandMoreIcon}
                          MenuProps={{
                            className: "status-select-menu",
                          }}
                          renderValue={(selected: any) => {
                            if (selected === 0) {
                              return 'All Status';
                            }
                            // console.log('selected',selected);

                            return selectStatusValues[selected - 1];
                          }}
                        >
                          {
                            selectStatusValues.map((item: string, index: number) => (
                              <MenuItem key={index} value={index + 1}>{item}</MenuItem>
                            ))
                          }
                        </StyledSelect>
                        <StyledSelect
                          labelId="sort-select-label"
                          id="sort-select"
                          value={selectedSortBy}
                          onChange={handleSelectedSortByChange}
                          className="marketplace-select"
                          IconComponent={ExpandMoreIcon}
                          MenuProps={{
                            className: "sort-select-menu",
                          }}
                          renderValue={(selected: any) => {
                            if (selected === 0) {
                              return 'Sort by';
                            }

                            return selectSortByValues[selected - 1];
                          }}
                        >
                          {
                            selectSortByValues.map((item: string, index: number) => (
                              <MenuItem key={index} value={index + 1}>{item}</MenuItem>
                            ))
                          }
                        </StyledSelect>
                      </Box>
                    </Box>
                  </StickyListItem>
                  {
                    !nftList.length
                      ? <Box sx={{ width: '100%', paddingTop: '100px' }}>
                        <img src={EmptyImage} alt="Marketplace Empty" width={400} height={400} style={{ margin: '0 auto', }} />
                      </Box>
                      : <Grid container spacing={4}>
                        {
                          nftList.filter(itemFilterAction).sort(itemFilterSort).map((item: INFT) => (
                            <Grid item xs={6} md={3} key={item.id}>
                              <MarketplactItem nft={item} />
                            </Grid>
                          ))
                        }
                      </Grid>
                  }
                </ul>
              </li>
            </StyledList>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default MarketPlace;

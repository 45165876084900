import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Bid, INFT, SaleType } from '../types/iNFT';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { dateCount, DateCount } from '../utils/utils';

interface MarketplactItemProps {
  nft: INFT,
}

const ItemBox = styled(Box)(() => ({
  fontSize: 100,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  height: '22vw',
  borderRadius: '16px',
  overflow: 'hidden',
  backgroundColor: '#292929',
}));

const MarketplaceItemInfo = styled(Box)(() => ({
  width: '100%',
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingTop: '16px',
}));

const ItemScore = styled(Typography)(({ theme }) => ({
  fontsize: 12,
  color: theme.palette.text.secondary,
}));

const ItemName = styled(Typography)(({ theme }) => ({
  fontsize: 12,
  color: theme.palette.common.white,
  textOverflow: 'ellipsis',
  wordBreak: 'keep-all',
}));

const ItemType = styled(Typography)(({ theme }) => ({
  fontsize: 12,
  color: theme.palette.text.secondary,
}));

const ItemPrice = styled(Typography)(() => ({
  textAlign: 'right',
  fontsize: 14,
  color: '#20FB4B',
}));

const getTopBidPrice = (nftItem: INFT) => {
  const bids: Bid[] = [...nftItem.bids];
  bids.sort((a: Bid, b: Bid) => b.price - a.price);
  return bids[0].price;
}

const DateCountBox = styled(Box)(({ theme }) => ({
  height: 30,
  position: 'absolute',
  right: '10px',
  bottom: '10px',
  borderRadius: '16px',
  backgroundColor: '#292929',
  border: `1px solid ${theme.palette.primary.light}`,
  fontSize: 12,
  color: theme.palette.primary.light,
  padding: '0 16px',
  display: 'flex',
  alignItems: 'center',
}));

const MarketplactItem = ({ nft }: MarketplactItemProps): JSX.Element => {
  const navigate = useNavigate();

  const [dateCountData, setDateCountData] = useState<DateCount | undefined>(undefined);

  const handleToINFTItemPage = (iNFTId: string) => {
    navigate(`/iNFT/${iNFTId}`);
  }

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (nft.saleType === SaleType.auction && nft.endTime) {
      if (nft.endTime - Date.now() > 0) {
        timer = setInterval(() => {
          if ((nft.endTime ?? 0) - Date.now() > 0) {
            const count: DateCount = dateCount(nft.endTime ?? 0);
            setDateCountData(count);
          } else {
            setDateCountData(undefined);
            if (timer) {
              clearInterval(timer);
              timer = null;
            }
          }
        }, 1000);
      }
    }

    return function cleanUp() {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ItemBox onClick={() => { handleToINFTItemPage(nft.id) }}>
        <Box sx={{ width: '100%', height: '76%', position: 'relative', }}>
          <img src={nft.cover} alt="NFT cover" width="100%" height="100%" />
          {
            dateCountData && (
              <DateCountBox>
                {`${dateCountData ? dateCountData.day : 0}`}
                days
                &nbsp;
                {`${dateCountData ? dateCountData.hour : 0}`.padStart(2, '0')}
                :
                {`${dateCountData ? dateCountData.minute : 0}`.padStart(2, '0')}
                :
                {`${dateCountData ? dateCountData.second : 0}`.padStart(2, '0')}
              </DateCountBox>
            )
          }
        </Box>
        <MarketplaceItemInfo>
          <ItemScore>
            Rarity Score: {nft.score}
          </ItemScore>
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
          >
            <Box sx={{ flex: 1, overflow: 'hidden', }}>
              <ItemName>
                {nft.name}
              </ItemName>
            </Box>
            <Box>
              <ItemType>
                {nft.saleType === SaleType.fixedPrice ? 'Fixed Price' : nft.bids.length ? 'Top Bid' : 'Min Bid'}
              </ItemType>
              <ItemPrice>
                {nft.saleType === SaleType.fixedPrice ? `${nft.fixedPrice} ETH` : nft.bids.length ? `${getTopBidPrice(nft)} WETH` : `${nft.minBid} WETH`}
              </ItemPrice>
            </Box>
          </Box>
        </MarketplaceItemInfo>
      </ItemBox>
    </>
  );
}

export default MarketplactItem;

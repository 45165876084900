import { Container, ContainerProps } from "@mui/material";
import { styled } from '@mui/material/styles';
import { ReactNode } from "react";

interface StyledContainerProps extends ContainerProps {
  isneedpaddingtop?: boolean,
}

const ContainerComponent = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'isneedpaddingtop',
})<StyledContainerProps>(({ isneedpaddingtop, theme }) => ({
  padding: 0,
  paddingTop: isneedpaddingtop ? 88 : 0,
  [theme.breakpoints.up('md')]: {
    padding: 0,
    paddingTop: isneedpaddingtop ? 88 : 0,
  }
}));

interface Props {
  children?: ReactNode,
  isneedpaddingtop?: boolean,
}

const OutContainer = ({ children, isneedpaddingtop }: Props): JSX.Element => {
  return (
    <>
      <ContainerComponent
        maxWidth="xl"
        isneedpaddingtop={isneedpaddingtop}
      >
        {children}
      </ContainerComponent>
    </>
  );
}

export default OutContainer;

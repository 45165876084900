import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const TestBox = styled(Box)(() => ({}));

const ProjectStake = (): JSX.Element => {
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <TestBox>
          stake
        </TestBox>
      </Box>
    </>
  );
}

export default ProjectStake;

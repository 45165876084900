import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store";

export enum SnackbarType {
  error,
  warning,
  info,
  success,
}

export interface SystemState {
  snackbarStatus: boolean,
  snackbarMessage: string,
  snackbarType: SnackbarType,
  loadingStatus: boolean,
}

export interface OpenSnackbarPayload {
  message: string,
  snackbarType: SnackbarType,
}

const initialState: SystemState = {
  snackbarStatus: false,
  snackbarMessage: '',
  snackbarType: SnackbarType.success,
  loadingStatus: false,
}

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    openSnackbar: (state, action: PayloadAction<OpenSnackbarPayload>) => {
      state.snackbarMessage = action.payload.message;
      state.snackbarType = action.payload.snackbarType;
      state.snackbarStatus = true;
    },
    closeSnackbar: (state) => {
      state.snackbarMessage = '';
      state.snackbarStatus = false;
    },
    openLoading: (state) => {
      state.loadingStatus = true;
    },
    closeLoading: (state) => {
      state.loadingStatus = false;
    },
  },
});

export const { openSnackbar, closeSnackbar, openLoading, closeLoading } = systemSlice.actions;

export const selectSnackbarStatus = (state: RootState) => state.system.snackbarStatus;
export const selectSnackbarMessage = (state: RootState) => state.system.snackbarMessage;
export const selectSnackbarType = (state: RootState) => state.system.snackbarType;
export const selectLoadingStatus = (state: RootState) => state.system.loadingStatus;

export default systemSlice.reducer;


import FilledStar from '../assets/icon_star_fill.png';
import EmptyStar from '../assets/icon_star_empty.png';

const getRatingContent = (value: number): JSX.Element[] => {
  const items: JSX.Element[] = [];
  for (let i = 0; i < 5; i++) {
    if (value >= 0 && value <= (i + 0.9)) {
      items.push(<img key={i} src={EmptyStar} alt="Rating Filled Star" width={15} height={15} style={{ marginRight: '8px' }} />)
    } else {
      items.push(<img key={i} src={FilledStar} alt="Rating Filled Star" width={15} height={15} style={{ marginRight: '8px' }} />)
    }
  }
  return items;
}

export default getRatingContent;

import { useState, useEffect } from 'react';
import { Review } from "../types/project";
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import getRatingContent from './ratingStar';
import moment from 'moment';

interface ProjectReviewsProps {
  reviews: Review[],
}

const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPagination-ul': {
    justifyContent: 'center',
  },
  '& .MuiPaginationItem-root': {
    backgroundColor: '#4F4F4F',
    fontSize: 14,
    color: '#151515'
  },
  '& .MuiPaginationItem-root.Mui-selected': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const ProjectReviews = ({ reviews }: ProjectReviewsProps): JSX.Element => {
  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [currentPageReviews, setCurrentPageReviews] = useState<Review[]>([]);

  useEffect(() => {
    changeCurrentPageReviews(currentPageCount);
  }, [currentPageCount]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPageCount(value);
  }

  const changeCurrentPageReviews = (pageCount: number) => {
    const copyReviews: Review[] = [...reviews];
    let endIndex = (pageCount - 1) * 4 + 4;
    if (endIndex > copyReviews.length - 1) {
      endIndex = copyReviews.length;
    }
    const result = copyReviews.slice((pageCount - 1) * 4, endIndex);
    setCurrentPageReviews(result);
  }

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
        <Box>
          {
            currentPageReviews.map((item: Review, idx) => (
              <ReviewItem key={`Review${idx}`} review={item} />
            ))
          }
        </Box>
        <StyledPagination
          count={Math.ceil(reviews.length / 4)}
          shape="rounded"
          hideNextButton
          hidePrevButton
          onChange={handlePageChange}
        />
      </Box>
    </>
  );
}

export default ProjectReviews;

interface ReviewItemProps {
  review: Review,
}

const ReviewItemBox = styled(Box)(() => ({
  width: '100%',
  backgroundColor: '#222222',
  borderRadius: '16px',
  padding: '32px',
  marginBottom: '20px',
}));

const ReviewItemInfoBox = styled(Box)(() => ({
  width: '100%',
  height: 30,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const ReviewItemInfoBoxTip = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.text.secondary,
}));

const ReviewItem = ({ review }: ReviewItemProps): JSX.Element => {
  return (
    <ReviewItemBox>
      <ReviewItemInfoBox>
        <Typography sx={{ fontSize: 16, }}>
          {review.title}
        </Typography>
        <ReviewItemInfoBoxTip>
          {moment(review.createTime).format('MMM D, YYYY')}
        </ReviewItemInfoBoxTip>
      </ReviewItemInfoBox>
      <ReviewItemInfoBox>
        <Box sx={{ display: 'flex', alignItems: 'center', }}>
          {
            getRatingContent(review.rating).map((item: JSX.Element) => item)
          }
        </Box>
        <ReviewItemInfoBoxTip>
          {review.userName}
        </ReviewItemInfoBoxTip>
      </ReviewItemInfoBox>
      <Typography sx={{ fontSize: 14, lineHeight: '30px', marginTop: '24px', wordBreak: 'break-all', }}>
        {review.content}
      </Typography>
    </ReviewItemBox>
  );
}

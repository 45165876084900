import { Bid, INFT, NFTInfo, Propety, Trade } from "../types/iNFT";
import { Attribute, BlandBox, ChainInfo, Claim, ClaimCollection, Project, Review, Stake } from "../types/project"

const transformChainInfo = (json: any): ChainInfo => {
  return {
    address: json.address,
    blockChain: json.blockChain,
  };
}

const transformAttributes = (json: any): Attribute[] => {
  const attributes: Attribute[] = [];
  json.forEach((data: any) => {
    attributes.push({
      id: data.id,
      editionName: data.editionName,
      cover: data.cover,
      score: data.score,
      amount: data.amount,
      probability: data.probability,
    });
  });
  return attributes;
}

const transformBlandBox = (json: any): BlandBox | null => {
  if (!json) return null;
  return {
    id: json.id,
    name: json.name,
    total: json.total,
    last: json.last,
    cover: json.cover,
    price: json.price,
    attribute: transformAttributes(json.attribute),
  };
}

const transformCollections = (json: any): ClaimCollection[] => {
  const claimCollections: ClaimCollection[] = [];
  json.forEach((data: any) => {
    claimCollections.push({
      id: data.id,
      collectionName: data.collectionName,
      nftName: data.nftName,
      cover: data.cover,
      score: data.score,
    });
  });
  return claimCollections;
}

const transformClaim = (json: any): Claim | null => {
  if (!json) return null;
  return {
    collections: transformCollections(json.collections),
    totalCount: json.totalCount,
    lastCount: json.lastCount,
    claimed: json.claimed,
  };
}

const transformStake = (json: any): Stake[] | null => {
  if (!json) return null;
  const stakes: Stake[] = [];
  json.forEach((data: any) => {
    stakes.push({
      id: data.id,
      cover: data.cover,
      score: data.score,
      total: data.total,
      last: data.last,
      stakeCount: data.stakeCount,
      stakeCoins: data.stakeCoins,
    });
  });
  return stakes;
}

const transformReview = (json: any): Review[] => {
  const reviews: Review[] = [];
  json.forEach((data: Review) => {
    reviews.push({
      id: data.id,
      title: data.title,
      userId: data.userId,
      userName: data.userName,
      createTime: data.createTime,
      content: data.content,
      rating: data.rating,
    })
  });
  return reviews;
}

export const transformProject = (json: any): Project => {
  return {
    id: json.id,
    name: json.name,
    creator: json.creator,
    createTime: json.createTime,
    cover: json.cover,
    banner: json.banner,
    ranking: json.ranking,
    volume: json.volume,
    totalStaked: json.totalStaked,
    totalYield: json.totalYield,
    preview: json.preview,
    chainInfo: transformChainInfo(json.chainInfo),
    projectType: json.projectType,
    blandBox: transformBlandBox(json.blandBox),
    claim: transformClaim(json.claim),
    stake: transformStake(json.stake),
    totalSupply: json.totalSupply,
    circulatingSupply: json.circulatingSupply,
    originalContractAddress: json.originalContractAddress ?? null,
    description: json.description,
    rating: json.rating,
    reviews: transformReview(json.reviews),
  };
}


/**
 *
 * -----------------分割线---------------------
 * 下面为NFT的转换
 *
 */

export const transformNFTInfo = (json: any): NFTInfo => {
  return {
    address: json.address,
    tokenId: json.tokenId,
    blockChain: json.blockChain,
  }
}

export const transformNFTTrades = (json: any): Trade[] => {
  const trades: Trade[] = [];
  json.forEach((data: any) => {
    trades.push({
      id: data.id,
      createTime: data.createTime,
      buyId: data.buyId,
      buyName: data.buyName,
      sellId: data.sellId,
      sellName: data.sellName,
      price: data.price,
    });
  });
  return trades;
}

export const transformNFTProperties = (json: any): Propety[] => {
  const properties: Propety[] = [];
  json.forEach((data: any) => {
    properties.push({
      id: data.id,
      title: data.title,
      name: data.name,
      percent: data.percent,
    })
  });
  return properties;
}

export const transformNFTBids = (json: any): Bid[] => {
  const bids: Bid[] = [];
  json.forEach((data: any) => {
    bids.push({
      id: data.id,
      price: data.price,
      bidTime: data.bidTime,
      bidUserId: data.bidUserId,
      bidUserName: data.bidUserName,
      endTime: data.endTime,
    });
  });
  return bids;
}

export const transformNFT = (json: any): INFT => {
  return {
    id: json.id,
    name: json.name,
    projectId: json.projectId,
    projectName: json.projectName,
    cover: json.cover,
    score: json.score,
    ranking: json.ranking,
    nftInfo: transformNFTInfo(json.chainInfo),
    trades: transformNFTTrades(json.trades),
    properties: transformNFTProperties(json.properties),
    saleType: json.saleType,
    fixedPrice: json.fixedPrice ?? null,
    endTime: json.endTime ?? null,
    minBid: json.minBid ?? null,
    bids: transformNFTBids(json.bids),
    listTime: json.listTime || 0,
  };
}

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled, alpha } from '@mui/material/styles';
import { Attribute, BlandBox } from '../types/project';
import { useAppDispatch } from '../app/hooks';
import { closeLoading, openLoading, openSnackbar, SnackbarType } from '../features/system/systemSlice';
import { sleep } from '../utils/utils';

interface ProjectBlandBoxProps {
  blandBox: BlandBox | null,
}

const BlandBoxName = styled(Typography)(() => ({
  fontSize: 30,
  fontFamily: 'fontBold',
  lineHeight: '68px',
}));

const BlandBoxCount = styled(Typography)(() => ({
  fontSize: 16,
  lineHeight: '30px',
}));

const BlandBoxPriceTitle = styled(Typography)(() => ({
  fontSize: 14,
  lineHeight: '30px',
}));

const BlandBoxPriceNumber = styled(Typography)(() => ({
  fontSize: 30,
  lineHeight: '50px',
  fontFamily: 'fontBold',
}));

const BlandBoxPriceUnit = styled(Typography)(() => ({
  fontSize: 30,
  lineHeight: '50px',
  marginLeft: '20px',
}));

const MintNowButton = styled(Button)(({ theme }) => ({
  borderRadius: '287px',
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.common.black,
  width: 400,
  height: 60,
  '&: hover': {
    backgroundColor: alpha(theme.palette.primary.light, 0.9),
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#222222',
    color: theme.palette.text.primary,
    fontSize: 16,
    border: 'none',
    borderRadius: '8px',
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#151515',
    fontSize: 20,
    border: 'none',
    fontFamily: 'fontBold',
    letterSpacing: '1px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#151515',
  border: 'none',
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 'none',
  },
}));

const ProjectBlandBox = ({ blandBox }: ProjectBlandBoxProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleCliceMintNow = async () => {
    dispatch(openLoading());
    await sleep(2800);
    dispatch(closeLoading());
    dispatch(openSnackbar({
      message: 'Mint Successed!',
      snackbarType: SnackbarType.success,
    }))
  }

  return (
    <>
      {
        blandBox && <Box sx={{ width: '100%' }}>
          <Box sx={{ width: '100%', display: 'flex' }}>
            <Box
              sx={{ width: 550, height: 550, }}
            >
              <img src={blandBox.cover} alt="bland box cover" width="100%" height="100%" style={{ objectFit: 'cover' }} />
            </Box>
            <Box sx={{ height: 1, width: 115 }} />
            <Box
              sx={{ flex: '1', display: 'flex', flexDirection: 'column', }}
            >
              <BlandBoxName>
                {blandBox.name}
              </BlandBoxName>
              <BlandBoxCount>
                The Remain Amont : {blandBox.last}/{blandBox.total}
              </BlandBoxCount>
              <Box sx={{ width: 1, height: 80, }} />
              <BlandBoxPriceTitle>
                Price
              </BlandBoxPriceTitle>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <BlandBoxPriceNumber>
                  {blandBox.price}
                </BlandBoxPriceNumber>
                <BlandBoxPriceUnit>
                  ETH
                </BlandBoxPriceUnit>
              </Box>
              <Box sx={{ width: '65%', height: 60, marginTop: '40px', }}>
                <MintNowButton variant="contained" onClick={handleCliceMintNow}>
                  Mint Now
                </MintNowButton>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: '100%', marginTop: '40px', }}>
            <TableContainer component={Box}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Edition</StyledTableCell>
                    <StyledTableCell>Rarity Score</StyledTableCell>
                    <StyledTableCell>Issue Amont</StyledTableCell>
                    <StyledTableCell>Probability</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    blandBox.attribute.map((row: Attribute, index: number) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                          {row.editionName}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.score[0]} ~ {row.score[1]}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.amount}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.probability}%
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      }
    </>
  );
}

export default ProjectBlandBox;

import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import OutContainer from './outCopntainer';
import Logo from '../logo.png';
import Typography from '@mui/material/Typography';

const LogoColumn = styled(Box)(() => ({
  width: 269,
  marginRight: '232px',
  display: 'flex',
  flexDirection: 'column',
}));

interface ColumnProps extends BoxProps {
  autoWidth?: boolean,
}

const Column = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'autoWidth',
})<ColumnProps>(({ autoWidth }) => ({
  width: autoWidth ? 'auto' : 269,
  display: 'flex',
  flexDirection: 'column',
}));

const LogoBox = styled(Box)(() => ({
  width: 269,
  height: 36,
  cursor: 'pointer',
}));

const TitleBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  height: 36,
}));

const TitleContent = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontFamily: 'fontBold',
  color: theme.palette.text.secondary,
  cursor: 'pointer',
}));

const FirstRowBox = styled(Box)(() => ({
  height: 20,
  margin: '16px 0 12px',
  display: 'flex',
  alignItems: 'center',
}));

const Row = styled(Box)(() => ({
  height: 20,
  margin: '12px 0',
  display: 'flex',
  alignItems: 'center',
}));

const CopyRight = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary,
}));

const Content = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.text.secondary,
  cursor: 'pointer',
}));

const PrivacyAndTerms = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  lineHeight: '30px',
  color: theme.palette.text.secondary,
  cursor: 'pointer',
}));

const Bottombar = (): JSX.Element => {
  return (
    <>
      <Box sx={{ width: '100%', height: 442, background: '#000000', }}>
        <OutContainer>
          <Box sx={{ paddingTop: '49px', display: 'flex', }}>
            <LogoColumn>
              <LogoBox>
                <img src={Logo} alt="Logo" width="100%" height="100%" />
              </LogoBox>
              <FirstRowBox>
                <CopyRight>
                  © 2021 Boo Universe
                </CopyRight>
              </FirstRowBox>
              <Row />
              <Row />
              <Row />
              <Row />
              <PrivacyAndTerms>
                Privacy Policy
              </PrivacyAndTerms>
              <PrivacyAndTerms>
                Terms of Service
              </PrivacyAndTerms>
            </LogoColumn>


            <Column>
              <TitleBox>
                <TitleContent>
                  Marketplace
                </TitleContent>
              </TitleBox>
              <FirstRowBox>
                <Content>
                  New
                </Content>
              </FirstRowBox>
              <Row>
                <Content>
                  Art
                </Content>
              </Row>
              <Row>
                <Content>
                  Music
                </Content>
              </Row>
              <Row>
                <Content>
                  Sports
                </Content>
              </Row>
              <Row>
                <Content>
                  Virtual Worlds
                </Content>
              </Row>
              <Row>
                <Content>
                  Utility
                </Content>
              </Row>
              <Row />
            </Column>


            <Column>
              <TitleBox>
                <TitleContent>
                  My Account
                </TitleContent>
              </TitleBox>
              <FirstRowBox>
                <Content>
                  Profile
                </Content>
              </FirstRowBox>
              <Row>
                <Content>
                  Favorites
                </Content>
              </Row>
              <Row>
                <Content>
                  My Projects
                </Content>
              </Row>
              <Row>
                <Content>
                  Settings
                </Content>
              </Row>
              <Row />
              <Row>
                <Content sx={{ fontFamily: 'fontBold', }}>
                  Stats
                </Content>
              </Row>
              <Row>
                <Content>
                  Rankings
                </Content>
              </Row>
            </Column>


            <Column>
              <TitleBox>
                <TitleContent>
                  Resource
                </TitleContent>
              </TitleBox>
              <FirstRowBox>
                <Content>
                  Help Center
                </Content>
              </FirstRowBox>
              <Row>
                <Content>
                  Platform Status
                </Content>
              </Row>
              <Row>
                <Content>
                  Suggestions
                </Content>
              </Row>
              <Row>
                <Content>
                  Twitter
                </Content>
              </Row>
              <Row>
                <Content>
                  Instagram
                </Content>
              </Row>
              <Row>
                <Content>
                  Discord
                </Content>
              </Row>
              <Row />
            </Column>


            <Column autoWidth>
              <TitleBox>
                <TitleContent>
                  Company
                </TitleContent>
              </TitleBox>
              <FirstRowBox>
                <Content>
                  About
                </Content>
              </FirstRowBox>
              <Row>
                <Content>
                  Careers
                </Content>
              </Row>
              <Row />
              <Row />
              <Row />
              <Row />
              <Row />
            </Column>
          </Box>
        </OutContainer>
      </Box>
    </>
  );
}

export default Bottombar;

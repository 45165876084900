export const sleep = (time: number): Promise<boolean> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  })
}

export interface DateCount {
  day: number,
  hour: number,
  minute: number,
  second: number,
}

export const dateCount = (endTime: number): DateCount => {
  // 现在时间
  const now: number = new Date().getTime();
  //截止时间
  const until: number = new Date(endTime).getTime();

  const days: number = (until - now) / 1000 / 3600 / 24;
  // 下面都是简单的数学计算
  const day: number = Math.floor(days);
  const hours: number = (days - day) * 24;
  const hour: number = Math.floor(hours);
  const minutes: number = (hours - hour) * 60;
  const minute: number = Math.floor(minutes);
  const seconds: number = (minutes - minute) * 60;
  const second: number = Math.floor(seconds);
  const count: DateCount = {
    day,
    hour,
    minute,
    second,
  }
  return count;
}

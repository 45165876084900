import Web3 from 'web3';
import * as ethers from 'ethers';

type networkChangeCallback = () => void;

export class NxWeb3 {
  private static _ins: NxWeb3;
  static get instance(): NxWeb3 {
    return this._ins || (this._ins = new NxWeb3());
  }

  // @ts-ignore
  web3: Web3;

  isInited: boolean = false;

  accountAddress: string = '';

  balance: number = 0;

  get ethBalance(): number {
    return this.dether(this.balance);
  }

  // 合约地址
  contractAddress: string = '';

  // 合约
  contractAbi: Object[] = [];

  constructor() {
    console.log('init');
  }

  async takeConstructor(callback: networkChangeCallback) {
    this.isInited = await this.init();
    if (this.isInited) {
      await this.connect();
      this.listenNetworkChange(callback);
      this.listenAccountChange();
    }
  }

  async init(): Promise<boolean> {
    try {
      // @ts-ignore
      if (!ethereum || !ethereum.isMetaMask) {
        alert('plase install MetaMask.');
        return false;
      }
      // @ts-ignore
      if (!window.web3) {
        alert('MetaMask not installed in your browser.');
        return false;
      }

      // @ts-ignore
      this.web3 = new Web3(window.ethereum);
      // @ts-ignore
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      // const chainId = await this.getChainId();
      // if( chainId !== 56){
      //   await this.setRinkeByTestChain();
      // }    
      // const rst = await this.web3.eth.getAccounts();
      // this.accountAddress = rst[0];
      // const _m = 'this message is want to provide your safe'
      // const _enm = await this.sign(_m);
      // const _r = await this.verify(
      //   {
      //     msg: _m,
      //     addr: this.accountAddress,
      //     sig: _enm,
      //   }
      // );
      // console.log(_r)
      return true;
    } catch (error) {
      alert('MetaMask not install in your browser.');
      return false;
    }
  }

  async getChainId(): Promise<number> {
    const id: number = await this.web3.eth.getChainId();
    return id;
  }

  async setBinanceMainChain(): Promise<any> {
    try {
      // @ts-ignore
      try {
        // @ts-ignore
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x38' }],
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        // @ts-ignore
        if (switchError.code === 4902) {
          try {
            // @ts-ignore
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId: '0x38',
                chainName: 'Binance Smart Chain Mainnet',
                nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18
                },
                rpcUrls: ['https://bsc-dataseed1.binance.org'],
                blockExplorerUrls: ['https://bscscan.com']
                }],
            });
          } catch (addError) {
            // handle "add" error
          }
        }
        // handle other "switch" errors
      }
    } catch (error) {
      // @ts-ignore
      if (error && error.code === 4001) {
        return false;
      }
    }
  }

  async setRinkeByTestChain(): Promise<any> {
    try {
      // @ts-ignore
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x4' }],
        });
    } catch (error) {
      // @ts-ignore
      if (error && error.code === 4001) {
        return false;
      }
    }
  }

  async connect() {
    try {
      // @ts-ignore
      const rst = await this.web3.eth.getAccounts();
      this.accountAddress = rst[0];

      this.balance = parseFloat(await this.web3.eth.getBalance(this.accountAddress));
    } catch (error) {
      console.log(error);
    }
  }

  listenAccountChange() {
    if (this.isInited) {
      // @ts-ignore
      window.ethereum.on('accountsChanged', async (accounts) => {
        this.accountAddress = this.web3.utils.toChecksumAddress(accounts[0]);
        this.balance = parseFloat(await this.web3.eth.getBalance(this.accountAddress));
      });
    }
  }

  listenNetworkChange(callback: networkChangeCallback) {
    if (this.isInited) {
      // @ts-ignore
      window.ethereum.on('chainChanged', async (_) => {
        const rst = await this.web3.eth.getAccounts();
        this.accountAddress = this.web3.utils.toChecksumAddress(rst[0]);

        this.balance = parseFloat(await this.web3.eth.getBalance(this.accountAddress));
        callback && callback();
      });
    }
  }

  ether(eth: Number): number {
    return parseInt(this.web3.utils.toWei(eth.toString()), 10);
  }

  dether(eth: number): number {
    return parseFloat((eth / 1000000000000000000).toFixed(2));
  }

  async sign(msg: string): Promise<string> {
    try {
      //@ts-ignore
    const signer= new ethers.providers.Web3Provider(window.ethereum).getSigner();
    const signature= await signer.signMessage(msg);
    const signerAddr = await signer.getAddress();
    console.log('signerAddr', signerAddr);
    return signature;
    } catch (error) {
      //@ts-ignore
      return error.toString()
    }
  }

  async verify({msg, addr, sig}: {msg:string, addr: string, sig:string}): Promise<boolean>{
    try {
      const signerAddr = await ethers.utils.verifyMessage(msg, sig);
      console.log(addr, signerAddr)
      if(addr !== signerAddr){
        return false;
      }
      return true;
    } catch (error) {
      console.log(error)
      return false
    }
  }
}

export const linkWallet = async (): Promise<boolean> => {
  // 如果没有链接钱包，就去链接钱包
  if (!NxWeb3.instance.isInited) {
    await NxWeb3.instance.takeConstructor(() => { });
  }

  if (!NxWeb3.instance.isInited) {
    return false;
  }

  return true;
}

export const isMianChain = async (): Promise<boolean> => {
  const chainId = await NxWeb3.instance.getChainId();
  if (chainId !== 4) {
    return false;
  }
  return true;
}

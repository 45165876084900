import { useEffect } from 'react';
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { styled } from '@mui/material/styles';
import OutContainer from "../../components/outCopntainer";
import { BooStoreSwiperComponent } from "../../components/swiper";
import { selectGetProjectStatus, selectProjectList } from '../../features/project/projectSlice';
import { useAppSelector } from '../../app/hooks';
import { StoreProjectItem, StoreProjectSkeleton } from '../../components/storeProjectItem';
import { Project } from '../../types/project';

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  lineHeight: '90px',
  fontFamily: 'fontBold',
  color: theme.palette.common.white,
  display: 'inline-block',
  height: 90,
}));


const BooStore = () => {
  const getListStatus = useAppSelector(selectGetProjectStatus);
  const projectList = useAppSelector(selectProjectList);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box sx={{ paddingBottom: '40px', paddingTop: '88px' }}>
        <Box sx={{ width: '100%', marginTop: '16px' }} >
          <BooStoreSwiperComponent />
        </Box>
        <OutContainer>
          <Title>
            Latest Listings
          </Title>
          <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
            {
              getListStatus === 'loading' ? (
                <Grid container spacing={3}>
                  <Grid item xs={6} md={4} >
                    <StoreProjectSkeleton />
                  </Grid>
                  <Grid item xs={6} md={4} >
                    <StoreProjectSkeleton />
                  </Grid>
                  <Grid item xs={6} md={4} >
                    <StoreProjectSkeleton />
                  </Grid>
                </Grid>
              ) : <Grid container spacing={3}>
                {
                  projectList.map((item: Project, index: number) => (
                    <Grid item xs={6} md={4} key={index}>
                      <StoreProjectItem project={item} />
                    </Grid>
                  ))
                }
              </Grid>
            }
          </Box>
        </OutContainer>
      </Box>
    </>
  );
}

export default BooStore;

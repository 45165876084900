import { iNFTData } from "../../json/iNFT";
import { INFT } from "../../types/iNFT";
import { transformNFT } from "../../utils/transformData";

export function fetchNFTList() {
  return new Promise<{ data: INFT[] }>((resolve) => {
    setTimeout(() => {
      const jsonData = iNFTData;
      const nfts: INFT[] = [];
      // for (var i = 0; i < 12; i++) {
      //   jsonData.forEach((data) => {
      //     nfts.push(transformNFT(data));
      //   })
      // }
      jsonData.forEach((data) => {
        nfts.push(transformNFT(data));
      })
      resolve({ data: nfts });
    }, 300);
  })
}

import { useState } from 'react';
import { AppBar, Button, CircularProgress, Toolbar, IconButton } from "@mui/material";
import { Box, styled } from "@mui/system";
// import { Link } from "react-router-dom";
import { useMatch, useNavigate } from 'react-router-dom';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import AccountCircle from '@mui/icons-material/AccountCircle';

import LogoImage from '../logo.png';
import { linkWallet, NxWeb3 } from '../utils/NxWeb3';

type NavItemProps = {
  label: string,
  to: string,
}

const disabledRoutePath: string[] = [];

const NavItem = ({ label, to }: NavItemProps) => {
  let match = useMatch({
    path: to,
  });
  const navigate = useNavigate();
  return (<Button className="m" variant="contained" disableElevation onClick={() => { if (disabledRoutePath.indexOf(to) > -1) { return; } navigate(to); }} disabled={match ? true : false}>{label}</Button>);
}

const ConnectToWalletButton = styled(LoadingButton)<LoadingButtonProps>(() => ({
  color: '#FFFFFF',
  backgroundColor: 'transparent',
  textTransform: 'none',
  fontFamily: 'fontNormal',
  fontsize: 12,
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const PrimarySearchAppBar = (): JSX.Element => {
  const [connectLoading, setConnectLoading] = useState<boolean>(false);
  const [address, setAddress] = useState<string>('');

  const handleConnectWallet = async () => {
    setConnectLoading(true);
    const hasLink: boolean = await linkWallet();
    if (hasLink) {
      setAddress(NxWeb3.instance.accountAddress);
    }
    setConnectLoading(false);
  }

  const getAddress = (): string => {
    if (!address) return '';
    return `${address.substring(0, 5)}****${address.substring(address.length - 4, address.length)}`
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ backgroundColor: '#272727', }}>
        <Toolbar sx={{ height: 78, paddingLeft: { md: '36px' }, paddingRight: { md: '40px' }, }}>
          <Box sx={{ cursor: 'pointer', }} onClick={() => { window.location.href = 'https://www.boouniverse.com'; }}>
            <img src={LogoImage} height={40} alt="appbar logo" />
          </Box>
          <Box sx={{ width: 85 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <NavItem label="Boo Store" to="/" />
            <NavItem label="Marketplace" to="/marketplace" />
            <NavItem label="Stats" to="/stats" />
            <NavItem label="Mining" to="/mining" />
            <NavItem label="Creators" to="/creators" />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          {
            !address ? <ConnectToWalletButton
              variant="text"
              loading={connectLoading}
              onClick={handleConnectWallet}
              loadingIndicator={<CircularProgress style={{ color: '#FFFFFF' }} size={16} />}
            >
              Connect Wallet
            </ConnectToWalletButton> : <p style={{ color: '#A6A6A6' }}>
              {getAddress()}
            </p>
          }
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            color="inherit"
            className="accountButton"
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default PrimarySearchAppBar;

import { Box } from "@mui/system";
import { styled } from '@mui/material/styles';
import { Skeleton, Typography } from "@mui/material";
import { Project } from "../types/project";
import { useNavigate } from 'react-router-dom';

interface StoreProjectItemProps {
  project: Project,
}

const ProjectName = styled(Typography)(({ theme }) => ({
  marginTop: '20px',
  fontSize: 16,
  fontFamily: 'fontBold',
  paddingLeft: '20px',
  display: 'inline-block',
  minHeight: '40px',
}));

const StoreProjectItemBox = styled(Box)(() => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  height: '22vw',
  minHeight: '390px',
  borderRadius: '16px',
  overflow: 'hidden',
  backgroundColor: '#292929',
}));

export const StoreProjectItem = ({ project }: StoreProjectItemProps): JSX.Element => {
  const navigate = useNavigate();

  const handleClickProject = (id: string) => {
    navigate(`/project/${id}`);
  }

  return (
    <>
      <StoreProjectItemBox onClick={() => { handleClickProject(project.id) }}>
        <img src={project.banner} width="100%" height="auto" alt="project banner" />
        {/* <Box sx={{ width: '100%', height: '80%', overflow: 'hidden' }}>

        </Box> */}
        <ProjectName>
          {project.name}
        </ProjectName>
      </StoreProjectItemBox>
    </>
  );
}

const SkeletonImageBox = styled(Box)(() => ({
  width: '100%',
  position: 'relative',
  '&:before': {
    content: `''`,
    display: 'inline-block',
    paddingBottom: '100%',
    width: '0.1px',
    backgroundColor: 'transparent'
  },
}));

export const StoreProjectSkeleton = (): JSX.Element => {
  return (
    <>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <SkeletonImageBox>
          <Skeleton
            sx={{ position: 'absolute', left: '0', top: '0' }}
            variant="rectangular"
            animation="wave"
            width="100%"
            height="87%"
          />
        </SkeletonImageBox>
      </Box>
    </>
  );
}

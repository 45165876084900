import { Suspense } from 'react';
// import logo from './logo.svg';
import {
  Routes,
  Route,
  // Link
} from "react-router-dom";
// import CssBaseline from '@mui/material/CssBaseline';


import './App.css';

import BooStore from './pages/boostore/BooStore';
import MarketPlace from './pages/marketplace/MarketPlace';
// import Counter from './pages/counter/Counter';
// import Login from './pages/login/login';
// import Register from './pages/register/register';
// import Creators from './pages/creators/creators';
import ProjectItem from './pages/projectItem/projectItem';
import PrimarySearchAppBar from './components/appbar';

// import CookieNotice from 'react-cookienotice'
import 'react-cookienotice/dist/index.css'
import Bottombar from './components/bottombar';
import { fetchDatas } from './utils/loadAppDatas';
import PageLoading from './components/pageLoading';
import INFTItem from './pages/iNFTItem/iNFTItem';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { closeSnackbar, selectLoadingStatus, selectSnackbarMessage, selectSnackbarStatus, selectSnackbarType, SnackbarType } from './features/system/systemSlice';
import { AlertColor } from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import CenterLoading from './components/loading';
import ComingSoonPage from './pages/comingSoon/comingSoon';

const resource = fetchDatas();

function App() {

  function GetAppContent() {
    const snackbarStatus = useAppSelector(selectSnackbarStatus);
    const snackbarMessage = useAppSelector(selectSnackbarMessage);
    const snackbarType = useAppSelector(selectSnackbarType);
    const loadingStatus = useAppSelector(selectLoadingStatus);

    const dispatch = useAppDispatch();

    const getAlertColor = (type: SnackbarType): AlertColor | undefined => {
      let alertColor: AlertColor | undefined = undefined;
      switch (type) {
        case SnackbarType.success:
          alertColor = 'success';
          break;
        case SnackbarType.error:
          alertColor = 'error';
          break;
        case SnackbarType.warning:
          alertColor = 'warning';
          break;
        case SnackbarType.info:
          alertColor = 'info';
          break;
        default:
          alertColor = undefined;
          break;
      }
      return alertColor;
    }

    const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
      dispatch(closeSnackbar());
    }

    const projectList = resource.projectList.read();
    console.log(projectList);
    return (
      <div className="app">
        <PrimarySearchAppBar />

        <div>
          <Routes>
            {/* <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} /> */}
            <Route path="/" element={<BooStore />} />
            <Route path="/marketplace" element={<MarketPlace />} />
            <Route path="/stats" element={<ComingSoonPage />} />
            <Route path="/mining" element={<ComingSoonPage />} />
            <Route path="/creators" element={<ComingSoonPage />} />
            <Route path="/project/*" element={<ProjectPage />} />
            <Route path="/iNFT/*" element={<INFTPage />} />
          </Routes>
        </div>
        <Bottombar />
        {/* <CookieNotice /> */}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbarStatus}
          message={snackbarMessage}
          autoHideDuration={8000}
          onClose={handleSnackbarClose}
        >
          <Alert severity={getAlertColor(snackbarType)} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Modal
          open={loadingStatus}
          disableEscapeKeyDown
          disableAutoFocus
          disableEnforceFocus
          disableRestoreFocus
        >
          <CenterLoading />
        </Modal>
      </div>
    );
  }

  return (
    <Suspense fallback={<PageLoading />} >
      <GetAppContent />
    </Suspense>
  );
}

export default App;

const ProjectPage = (): JSX.Element => (
  <Routes>
    <Route path=":projectId" element={<ProjectItem />} />
  </Routes>
);

const INFTPage = (): JSX.Element => (
  <Routes>
    <Route path=":iNFTId" element={<INFTItem />} />
  </Routes>
)


import { projectData } from "../../json/project";
import { Project } from "../../types/project";
import { transformProject } from "../../utils/transformData";

export function fetchProjectList() {
  return new Promise<{ data: Project[] }>((resolve) =>
    setTimeout(() => {
      const jsonData = projectData;
      const projects: Project[] = [];
      jsonData.forEach((data) => {
        projects.push(transformProject(data));
      });
      resolve({ data: projects });
    }, 1500)
  );
}

import { store } from "../app/store";
import { getNFTListAsync } from "../features/nft/nftSlice";
import { getProjectListAsync } from "../features/project/projectSlice";

const wrapPromise = (promise: Promise<any>) => {
  let status = "pending";
  let result: PromiseRejectedResult;
  let suspender = promise.then(
    (r) => {
      status = "success";
      result = r;
    },
    (e) => {
      status = "error";
      result = e;
    }
  );
  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    }
  };
}

const fetchProjectList = () => {
  return new Promise((resolve) => {
    store.dispatch(getProjectListAsync()).then(() => {
      resolve(true);
    });
  });
}

const fetchNFTList = () => {
  return new Promise((resolve) => {
    store.dispatch(getNFTListAsync()).then(() => {
      resolve(true);
    });
  });
}

export const fetchDatas = () => {
  let projectPromise = fetchProjectList();
  let nftPromise = fetchNFTList();
  return {
    projectList: wrapPromise(projectPromise),
    nftList: wrapPromise(nftPromise),
  }
}

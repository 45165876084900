export enum ProjectType {
  blandBox,
  claim,
  stake,
}

export interface Attribute {
  id: string,
  editionName: string,
  cover: string,
  score: number[],
  amount: number,
  probability: number,
}

export interface ChainInfo {
  address: string,
  blockChain: string,
}

export interface BlandBox {
  id: string,
  name: string,
  total: number,
  last: number,
  cover: string,
  price: number,
  attribute: Attribute[],

}

export interface ClaimCollection {
  id: string,
  collectionName: string,
  nftName: string,
  cover: string,
  score: number,
}
export interface Claim {
  collections: ClaimCollection[],
  totalCount: number,
  lastCount: number,
  claimed: string[],  // 里面存放ClaimCollection的ID

}

export interface Stake {
  id: string,
  cover: string,
  score: number,
  total: number,
  last: number,
  stakeCount: number,
  stakeCoins: string,
}

export interface Review {
  id: string,
  title: string,
  userId: string,
  userName: string,
  createTime: number,
  content: string,
  rating: number,
}

export interface Project {
  id: string,
  name: string,
  creator: string,
  createTime: number,
  cover: string,
  banner: string,
  ranking: number,
  volume: number,
  totalStaked: number,
  totalYield: number,
  preview: string[],
  chainInfo: ChainInfo,
  projectType: ProjectType,
  blandBox: BlandBox | null,
  claim: Claim | null,
  stake: Stake[] | null,
  totalSupply: number,
  circulatingSupply: number,
  originalContractAddress: string | null,
  description: string,
  rating: number,
  reviews: Review[]
}

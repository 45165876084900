
export enum SaleType {
  fixedPrice,
  auction,
}

export interface Trade {
  id: string,
  createTime: number,
  buyId: string,
  buyName: string,
  sellId: string,
  sellName: string,
  price: number,
}

export interface Propety {
  id: string,
  title: string,
  name: string,
  percent: number,
}

export interface Bid {
  id: string,
  price: number,
  bidTime: number,
  bidUserId: string,
  bidUserName: string,
  endTime: number,
}

export interface NFTInfo {
  address: string,
  tokenId: string,
  blockChain: string,
}

export interface INFT {
  id: string,
  name: string,
  projectId: string,
  projectName: string,
  cover: string,
  score: number,
  ranking: number,
  nftInfo: NFTInfo,
  trades: Trade[],
  properties: Propety[],
  saleType: SaleType,
  fixedPrice: number | null,
  endTime: number | null,
  minBid: number | null,
  bids: Bid[],
  listTime: number,
}
